//#### Project variables
$primary-font: "Roboto", sans-serif;
$primary-font-condensed: "Roboto Condensed";
$page-min-width: 320px;
//#### Bootstrap variables
$primary: #005197;
$secondary: #fbba00;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
$card-spacer-y: 1rem;
$card-spacer-x: 1rem;
$font-family-sans-serif: $primary-font, "Helvetica Neue", Helvetica, Arial, sans-serif;
$enable-responsive-font-sizes: true;
//#### Imports
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap-select/sass/bootstrap-select";
@import "../inc/fonts";
@import "../inc/page";
@import "../inc/content";
@import "../inc/modules";
@import "../inc/misc";
@import "../inc/mod";
