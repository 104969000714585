//#### Forms
// Yellow required fields
input:required:invalid,
textarea:required:invalid,
select:required:invalid,
select:required:invalid + .dropdown-toggle,
select:required:invalid + .invalid-feedback + .dropdown-toggle {
  background-color: lightgoldenrodyellow;
}

//#### Style invalid checkbox
.form-check.is-invalid {
  color: $danger;
}


.form-check-input:required:invalid + label {
  background-color: lightgoldenrodyellow;
  padding-left: .3rem;
  padding-right: .3rem;
  //border: 1px solid #ced4da;
  box-shadow: 0px 0px 0px 1px #ced4da;
  position: relative;
  // left: -.3rem;
  margin-right: -.3rem;
}

.form-control:focus {
  box-shadow: none;
  border-color: $primary;
}

.bootstrap-select {
  display: flex !important;
  flex-direction: column;
  background-color: transparent;

  .dropdown-toggle {
    padding: $input-btn-padding-y $input-btn-padding-x;
    border: $input-btn-border-width solid $input-border-color;
    background-color: #fff;
  }

  .dropdown-toggle:focus,
  &.show .dropdown-toggle {
    background-color: #fff;
    box-shadow: none;
    border-color: $primary;
    outline: none !important;
  }

  .dropdown-menu {
    padding: 0;

    .selected:hover {
      color: #fff;
    }
  }

  .invalid-feedback {
    order: 99;

    &.filled + .dropdown-toggle {
      border-color: red;
    }
  }
}

input.datepicker {
  padding: .375rem .75rem;
  border-radius: inherit;
}

.datepicker.datepicker-dropdown {
  border-radius: 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, .2);
}

.datepicker td.active,
.datepicker span.active {
  border-radius: 0;
  background-color: $primary !important;
  color: #fff !important;
  background-image: none !important;
}

.datepicker td,
.datepicker th,
.datepicker td.focused,
.datepicker span.focused,
.datepicker td:hover,
.datepicker th:hover,
.datepicker span:hover {
  border-radius: 0 !important;
}

.datepicker thead {
  border-bottom: 1px solid #eee;
}

.form-check.is-invalid + .invalid-feedback {
  display: block;
  padding-left: 1.25rem;
  margin-top: 0;
  margin-bottom: .3rem;
}

.form-check.form-check-inline + .invalid-feedback {
  display: none;
}