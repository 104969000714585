//#### Default collapse
.accordion {
  .card-body {
    padding: 1rem;


    & > :last-child {
      margin-bottom: 0;
    }
  }

  .card-header {
    padding: 0 1rem;

    .btn {
      text-align: left;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

}

/* hallo ich bin doof */
.accordion > .card
{
    overflow: visible !important;
}

.form-control, .form-control-plaintext, .btn-bs {
  padding: 0 .5rem;
  height: calc(1.5rem + 2px);
}

.auth-form .form-control {
  height: auto;
}

.dropdown-toggle:disabled{
  background-color: #e9ecef !important;
}


/* .antrag-document {
  .input-group {
    margin-bottom: .1rem;
  }

  & > :last-child {
    margin-bottom: 0;
  }


  .dropdown-item {
    min-width: max-content;
  }
}
*/

.btn-group {
  .btn {
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }
  }

  & .btn-group:last-child > .btn {
    margin-right: 0;
  }
}

.dropdown-menu label {
  margin-bottom: 0;
}

.form--filter {
  background-color: $light;
  padding: 1rem;
  margin-bottom: 1rem;

  label, .form-group {
    margin-bottom: 0;
  }
}

.table--mod {
  td, th {
    vertical-align: middle !important;
    line-height: 1;
  }
}

.card-body h1.separator, .accordion .card-body h2.separator, .accordion .card-body h3.separator, .accordion .card-body h4.separator, .accordion .card-body h5.separator, .accordion .card-body h6.separator {
  padding: 1rem 1.25rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 1.25rem;
}

/*
h1, h2, h3, h4, h5, h6, label, thead th, #page-header, #page-footer, .card-header, .condensed {
  font-family: $primary-font-condensed;
}
*/



.fa-trash
{
    color: $red;
}


.table th, .table td {
  padding: 0.1rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.c-pointer{
  cursor: pointer;
}

a > svg
{
  color: $body-color;
}


.table td.fit, 
.table th.fit {
    white-space: nowrap;
    width: 1%;
}


.modal .form-group {
  margin-bottom: 0.4rem;
}

/* Selectpicker */
.bootstrap-select .dropdown-toggle {
  padding: 0.0 0.75rem !important;
}

.container-bigselectpicker .bootstrap-select .dropdown-toggle
{
  padding: 0.375rem 0.75rem !important;
}